import React from "react";
import { Grid } from "@material-ui/core";
import ptBr from "../../../config/texts/pt-br";
import {useNavigate} from 'react-router-dom';
import {
  ButtonContained,

  ButtonText,

} from "../../../genericComponents";
import Styles from "../ForgetPasswordStyles";
import { FormFull } from "form-full";
import { hooks, validations } from "../../../utils";
import './stepcode.css';

function StepCode({ RenderSwiperDots, onSubmit, goBack, loadingOut , sendCode}) {
  const texts = ptBr.forgetPassword;
  const navigate = useNavigate();
  const [ref, setRef] = React.useState();
  const [timer, setTimer] = React.useState(30);
  const [error, setError] = React.useState(false);
  const [buttonDisabled, setButton] = React.useState(true);
  const {loading, call} = hooks.useRequest();

  React.useEffect(() => {
    function minusOneSec() {
      setTimer(timer - 1);
    }
    if (timer >= 0) {
      setTimeout(function () {
        minusOneSec();
      }, 1000);
    }
  }, [timer, setTimer]);

  const sendTokenData = async (r) => {
    const a = await r.testErrorsAndReturnData();

    if (!a?.hasError) {

      setError(false);
      const dataRequest = {
        code:
          a.data.code0 +
          a.data.code1 +
          a.data.code2 +
          a.data.code3 +
          a.data.code4 +
          a.data.code5,
      };

      onSubmit(dataRequest);
    } else {
      setError(true);
    }
  };
  
  
  React.useEffect(() => {
    function minusOneSec() {
      setTimer(timer - 1);
    }
    if (timer >= 0) {
      setTimeout(function () {
        minusOneSec();
      }, 1000);
    }
  }, [timer, setTimer]);

  
  const CheckForm = async (r) => {
    const a = await r.testErrorsAndReturnData();
    setButton(false);
    if (!a?.hasError) {
      setError(false);
    } else {
      setError(true);
    }
  };
  const CtrlV = (a, ref) => {
    ref.setValue("code0", a[0]);
    ref.setValue("code1", a[1]);
    ref.setValue("code2", a[2]);
    ref.setValue("code3", a[3]);
    ref.setValue("code4", a[4]);
    ref.setValue("code5", a[5]);
  };

  const ReSendCode = async () => {
    call(null, sendCode(), (response) => {
      if (response.ok) {
        setTimer(30);
      } else {
      }
    })  

  }
  return (
    <FormFull formRef={setRef} onSubmit={() => {}}>
      <Styles.Content className="corpoesqueci">
        <Styles.Title className="tituloesqueci">{texts.titleStepTwo}</Styles.Title>
        <Styles.Subtitle className="subtituloesqueci"> {texts.subTitle[1]}</Styles.Subtitle>
        <Grid container spacing={1} justify="center">
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <Styles.InputCode
              required
              onBlur={(value, r) => {
                CheckForm(r);
              }}
              disableError
              name="code0"
              id="code0"
              maxLength={1}
              nextInput="code1"
              validations={validations.validToken}
              onChange={(text, r) => {
                if (text.length <= 2 && text.length > 0) {
                  const b = document.getElementById("code1");
                  b.focus();
                } else {
                  CtrlV(text, r);
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <Styles.InputCode
              disableError
              onBlur={(value, r) => {
                CheckForm(r);
              }}
              required
              name="code1"
              id="code1"
              maxLength={1}
              nextInput="code2"
              validations={validations.validToken}
              onChange={(text, a) => {
                if (text.length <= 2 && text.length > 0) {
                  const b = document.getElementById("code2");
                  b.focus();
                } else if (text.length === 0) {
                  const c = document.getElementById("code0");
                  c.focus();
                } else {
                  CtrlV(text, ref, ref);
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <Styles.InputCode
              disableError
              required
              name="code2"
              onBlur={(value, r) => {
                CheckForm(r);
              }}
              id="code2"
              maxLength={1}
              nextInput="code5"
              validations={validations.validToken}
              onChange={(text, a) => {
                if (text.length <= 2 && text.length > 0) {
                  const b = document.getElementById("code3");
                  b.focus();
                } else if (text.length === 0) {
                  const c = document.getElementById("code1");
                  c.focus();
                } else {
                  CtrlV(text, ref, ref);
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <Styles.InputCode
              disableError
              required
              onBlur={(value, r) => {
                CheckForm(r);
              }}
              name="code3"
              id="code3"
              maxLength={1}
              nextInput="code4"
              validations={validations.validToken}
              onChange={(text, a) => {
                if (text.length <= 2 && text.length > 0) {
                  const b = document.getElementById("code4");
                  b.focus();
                } else if (text.length === 0) {
                  const c = document.getElementById("code2");
                  c.focus();
                } else {
                  CtrlV(text, ref);
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <Styles.InputCode
              disableError
              required
              onBlur={(value, r) => {
                CheckForm(r);
              }}
              name="code4"
              id="code4"
              maxLength={1}
              nextInput="code0"
              validations={validations.validToken}
              onChange={(text, a) => {
                if (text.length <= 2 && text.length > 0) {
                  const b = document.getElementById("code5");
                  b.focus();
                } else if (text.length === 0) {
                  const c = document.getElementById("code4");
                  c.focus();
                } else {
                  CtrlV(text, ref);
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <Styles.InputCode
              disableError
              required
              onBlur={(value, r) => {
                CheckForm(r);
              }}
              name="code5"
              id="code5"
              maxLength={1}
              nextInput="code0"
              validations={validations.validToken}
              onChange={(text, a) => {
                if (text.length > 2) {
                  CtrlV(text, ref);
                } else if (text.length === 0) {
                  const c = document.getElementById("code4");
                  c.focus();
                }
              }}
            />
          </Grid>
          {error ? (
            <Grid item xs={12}>
              <Styles.ErrorMessage>Campo Obrigatório</Styles.ErrorMessage>
            </Grid>
          ) : null}
        </Grid>
        <Styles.ButtonReSend
          fullWidth={false}
          loading={loading || loadingOut}
          onClick={() => ReSendCode()}
          disabled={timer > 0 ? true : false}>
            
          {timer > 0 ? `${texts.resendCode} (${timer}s)` : texts.resendCode}
        </Styles.ButtonReSend>
        <ButtonContained
          name="continue"
          loading={loading || loadingOut}
          feedback={true}
          disabled={buttonDisabled}
          onClick={() => sendTokenData(ref)}
          label={texts.continue[1]}
          className="botaocriar"
        />
      </Styles.Content>

      <Styles.Footer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {RenderSwiperDots(texts.titleStepTwo)}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={7}
            style={{ display: "none", justifyContent: "space-between" }}>
            <ButtonText
              name="back"
              loading={loading || loadingOut}
              onClick={goBack}
              fullWidth={false}>
              <Styles.ArrowCircle>
                <Styles.ArrowLeft />
              </Styles.ArrowCircle>
              {texts.onBack}
            </ButtonText>
            <ButtonText
              name="next"
              onClick={() => sendTokenData(ref)}
              loading={loading || loadingOut}
              disabled={buttonDisabled}
              fullWidth={false}>
              {texts.onNext}
              <Styles.ArrowCircle>
                <Styles.ArrowRight />
              </Styles.ArrowCircle>
            </ButtonText>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonText className="botaoacoesvoltar" onClick={() => navigate('/')} name="home"  loading={loading || loadingOut} fullWidth={false}>
              {texts.onBackMenu}
            </ButtonText>
          </Grid>
        </Grid>
      </Styles.Footer>
    </FormFull>
  );
}

export default StepCode;
