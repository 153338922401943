import Login from "./login/login";
import ForgetPassword from "./forgetPassword/forgetPassword";
import UserRegister from "./newAccount/NewAccount";
import Home from "./home/home";
import Settings from "./settings/settings";
import Reports from "./reports/reports";
import ReportReader from "./reportReader/reportReader";
import Bulletins from "./bulletins/bulletins";
import Cadastro from "./cadastro/cadastro";
import CadastroIndicacaoParceiro from "./cadastroIndicacaoParceiro/cadastroIndicacaoParceiro";
import NovoCadastroIndicacaoParceiro from "./cadastroIndicacaoParceiro/novocadastroIndicacaoParceiro";
import StepCadastroIndicacaoParceiro from "./cadastroIndicacaoParceiro/stepcadastroIndicacaoParceiro";
import LoginSso from "./login/loginsso";
const pages = {
  Login,
  ForgetPassword,
  Home,
  UserRegister,
  Settings,
  Reports,
  ReportReader,
  Bulletins,
  Cadastro,
  CadastroIndicacaoParceiro,
  NovoCadastroIndicacaoParceiro,
  StepCadastroIndicacaoParceiro,
  LoginSso
};
export default pages;
