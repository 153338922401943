import styled from "styled-components";
import { MediaQueries, Spacing } from "../../config";

const EcomLogo = styled.img(({ theme }) => {
  // const { spacing } = theme;
  return {
    height: "auto",
    position: "relative",
    top: "0.8rem",
    [MediaQueries.smDown]: {
      
    },
  };
});

const Line = styled.div(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    display: "block",
    border: 0,
    borderBottom: "1px solid #004b90",
    paddingTop: Spacing(3)
  };
});

const Styles = {
  EcomLogo,
  Line,
};

export default Styles;
