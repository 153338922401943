import styled from "styled-components";
import colors from "../../config/colors";
import fonts from "../../config/fonts";
import { MediaQueries, Spacing } from "../../config";
import { ButtonContained, FontStyles } from "../../genericComponents";
import { MenuItem, Select } from "@material-ui/core";
import { ReactComponent as Pin } from "../../assets/svg/icone-flecha-select.svg";
import { IconButton } from "@material-ui/core";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100vw",
    height: `calc(100vh - ${spacing(17)}px)`,
    flexDirection: "column",

    justifyContent: "flex-start",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "none",
  };
});

const Content = styled.div(({ theme, alternative, marginTop }) => {
  const { palette: colors } = theme;
  return {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: `100%`,
    backgroundColor: alternative ? colors.background.secondary : "transparent",
    paddingInline: "13%",
    paddingBlock: Spacing(15.5),
    paddingTop: marginTop && Spacing(6),
    [MediaQueries.mdDown]: {
      paddingInline: Spacing(4),
    },
  };
});

const Header = styled.div(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: "32rem",
    height: "fit-content",
    marginBottom: Spacing(3),
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
  };
});

const Title = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: colors.purple,
    opacity: 0.88,
    margin: 0,
    textAlign: "left",
    ...FontStyles.bold42,
    paddingTop: Spacing(8),
  };
});

const Subtitle = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: colors.grayText,
    ...FontStyles.medium16,
    opacity: 0.88,
    margin: 0,
    paddingTop: Spacing(2.5),
    paddingBottom: Spacing(3),
  };
});

const EcomLogo = styled.img(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: Spacing(15),
    height: "auto",
  };
});

const P = styled.p(({ theme, gray }) => {
  // const { spacing } = theme;
  return {
    fontFamily: gray ? fonts.medium : fonts.bold,
    color: gray ? colors.grayText : colors.purple,
    fontSize: fonts.sizes.regular,
  };
});

const Span = styled.span(({ theme }) => {
  // const { spacing } = theme;
  return {
    fontFamily: fonts.bold,
    color: colors.purple,
    fontSize: fonts.sizes.regular,
    marginLeft: 3,
  };
});

const ImageBG = styled.div(({ $Image }) => {
  return {
    width: "100%",
    height: "100%",
    backgroundImage: $Image,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // boxShadow: "inset 0 0 0 1000px #acca1359",
  };
});

//----

const Card = styled.div(({ $Image }) => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    border: "1px solid #004b90",
    borderRadius: Spacing(0.6),
  };
});
const CardHeader = styled.div(({ theme, noLine }) => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: !noLine && Spacing(4),
    borderBottom: !noLine && "1px solid #004b90",
  };
});
const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    margin: 0,
    padding: 0,
    color: colors.text.secondary,
    ...FontStyles.bold22,
  };
});
const Block = styled.div(({ theme }) => {
  return {
    padding: Spacing(4),
  };
});
const SelectLabel = styled.p(({ theme }) => {
  return {
    margin: 0,
    padding: 0,
    marginRight: Spacing(1),
    color: "#DEA017",
    ...FontStyles.bold22,
  };
});
const SelectRegion = styled(Select)(() => {
  return {
    margin: 0,
    padding: 0,
    color: "#DEA017",
    ...FontStyles.bold22,
    "& .MuiSelect-select": {
      padding: 0,
    },
  };
});
const SelectItens = styled(MenuItem)(() => {
  return {
    color: "#DEA017",
    ...FontStyles.bold16,
  };
});
const SelectIcon = styled(Pin)(() => {
  return {
    color: "#DEA017",
    width: Spacing(2),
    height: Spacing(2),
  };
});
const Footer = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    margin: 0,
    marginTop: Spacing(1),
    padding: 0,
    width: "100%",
    textAlign: "center",
    color: colors.text.primary,
    opacity: 0.8,
    ...FontStyles.italic14,
  };
});
const ShadowCard = styled.div(({ theme }) => {
  return {
    marginTop: Spacing(6),

    width: "100%",
    height: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: `0px ${Spacing(10.5)}px ${Spacing(7.5)}px #2C4D741A`,
    borderRadius: Spacing(0.6),
    padding: Spacing(5.5),
  };
});

const Row = styled.div(({ theme, centralized }) => {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "row",

    justifyContent: centralized ? "center" : "space-between",
    alignItems: "center",
    [MediaQueries.mdDown]: {
      justifyContent: "flex-start",
      overflowX: "auto",
    },
  };
});
const ValueCard = styled.div(({ theme }) => {
  return {
    flex: 1,
    maxWidth: Spacing(25),

    margin: "auto",
    [MediaQueries.mdDown]: {
      marginInline: Spacing(4),
    },
  };
});

const VerticalLine = styled.div(({ theme }) => {
  return {
    flex: 1,
    borderRight: "2px solid #EFEFEF",
  };
});

const ReportsCards = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.background.paper,

    margin: "auto",
    padding: spacing(6),
    borderRadius: spacing(0.5),
    boxShadow: "5px 5px 15px #0000001A",
    minHeight: spacing(61.5),
    maxWidth: spacing(53),
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    marginInline: spacing(2),
    minWidth: spacing(34.37),
    [MediaQueries.mdDown]: {
      minWidth: spacing(42),
    },
  };
});

const ReportsIcon = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.background.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    height: spacing(20.5),
    width: spacing(20.5),
    borderRadius: spacing(20),
  };
});

const SmallButton = styled(ButtonContained)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.background.alternative,
    ...FontStyles.bold16,
    paddingInline: spacing(9),
    marginTop: spacing(5),
  };
});

const FooterPage = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    paddingBlock: spacing(6),
    paddingInline: spacing(30),
    display: "flex",
    justifyContent: "space-between",
  };
});

const ButtonIcon = styled(IconButton)(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    borderRadius: 0,
    marginRight: spacing(2),
  };
});
const Styles = {
  ButtonIcon,
  FooterPage,
  Row,
  ValueCard,
  Container,
  Content,
  Title,
  Subtitle,
  Header,
  EcomLogo,
  P,
  Span,
  ImageBG,
  Card,
  CardTitle,
  CardHeader,
  SelectRegion,
  SelectItens,
  SelectLabel,
  SelectIcon,
  Footer,
  Block,
  ShadowCard,
  VerticalLine,
  ReportsCards,
  SmallButton,
  ReportsIcon,
};

export default Styles;
