import styled from "styled-components";
import colors from "../../config/colors";
import fonts from "../../config/fonts";
import FontStyles from "../../genericComponents/styles/fontStyles";
import { Spacing } from "../../config";
import { ButtonText, Input } from "../../genericComponents";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { FormHelperText } from "@material-ui/core";

const Container = styled.div(({ theme }) => {
  // const { palette: colors, spacing } = theme;
  return {
    display: "flex",
    width: "100%",
    height: "100vh",
    flexDirection: "column",
    padding: Spacing(2, 3, 2, 3),
    justifyContent: "space-between",
    overflow: "auto",
  };
});

const Content = styled.div(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",

    paddingInline: "19%",
    // marginInline: "auto",
    // overflowY: "auto",
    textAlign: "left",
    marginTop: Spacing(10),
  };
});

const Header = styled.div(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: "32rem",
    height: "fit-content",
    margin: Spacing(3, 0, 3),
    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-start",
  };
});

const Title = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: '#097dbb',
    opacity: 0.88,
    margin: 0,
    textAlign: "left",
    ...FontStyles.bold42,
    // paddingTop: Spacing(8),
  };
});

const Subtitle = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: '#097dbb',
    ...FontStyles.medium16,
    opacity: 0.88,
    margin: 0,
    paddingTop: Spacing(2.5),
    // paddingBottom: Spacing(3),
    paddingBottom: Spacing(4),
  };
});

const InputName = styled(Input)(() => {
  // const { spacing } = theme;
  return {
    textTransform: "capitalize",
  };
});

const EcomLogo = styled.img(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: Spacing(15),
    height: "auto",
  };
});

const P = styled.p(({ theme, gray }) => {
  // const { spacing } = theme;
  return {
    fontFamily: gray ? fonts.medium : fonts.bold,
    color: gray ? colors.grayText : colors.purple,
    fontSize: fonts.sizes.regular,
  };
});

const Span = styled.span(({ theme }) => {
  // const { spacing } = theme;
  return {
    fontFamily: fonts.bold,
    color: colors.purple,
    fontSize: fonts.sizes.regular,
    marginLeft: 3,
  };
});

const ImageBG = styled.div(({ $Image }) => {
  return {
    width: "100%",
    height: "100%",
    backgroundImage: $Image,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // boxShadow: "inset 0 0 0 1000px #acca1359",
  };
});

const Footer = styled.div(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: "100%",
    height: "fit-content",

    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-end",
    paddingInline: "19%",
    marginInline: "auto",
  };
});

const ArrowCircle = styled.div(() => {
  // const { spacing } = theme;
  return {
    border: "1px solid #004b90",
    borderRadius: Spacing(20),
    marginRight: Spacing(2),
    height: Spacing(3),
    width: Spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});

const ArrowLeft = styled(ArrowBackIcon)(() => {
  // const { spacing } = theme;
  return {
    height: Spacing(1.8),
    width: Spacing(1.8),
  };
});

const ArrowRight = styled(ArrowForwardIcon)(() => {
  // const { spacing } = theme;
  return {
    height: Spacing(1.8),
    width: Spacing(1.8),
  };
});

const InputCode = styled(Input)(({ theme }) => {
  return {
    width: "100%",
    height: "80%",
    color: "#464646",
    textTransform: "uppercase",
    "& .MuiInput-input": {
      fontSize: 30,
      marginLeft: 10,
      textTransform: "uppercase",
    },
  };
});

const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  return {
    color: "red",
    minHeight: Spacing(2.5),
  };
});

const ButtonReSend = styled(ButtonText)(({ theme }) => {
  return {
    marginBottom: Spacing(4),
  };
});

const Styles = {
  Container,
  Content,
  Title,
  Subtitle,
  Header,
  EcomLogo,
  P,
  Span,
  ImageBG,
  Footer,
  ArrowCircle,
  ArrowLeft,
  ArrowRight,
  InputCode,
  ErrorMessage,
  ButtonReSend,
  InputName,
};

export default Styles;
