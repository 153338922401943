import React from "react";
import Styles from "./styles";
import { api } from "../../services";
import { CustomText, FontStyles, Footer } from "../../genericComponents";
import { Icons, MediaQueries, Spacing } from "../../config";
import fonts from "../../config/fonts";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { hooks } from "../../utils";

import { CircularProgress, MenuItem, withStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";

import base64 from "../../utils/storage/Base64";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { customModal } from "../../genericComponents/modals/utils";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ListItem = withStyles({
  root: {
    color: "#C2C2C2",
    ...FontStyles.bold16,
    "&$selected": {
      backgroundColor: "white",
      color: "#004a8f",
      "& .MuiListItemIcon-root": {
        color: "#004a8f",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#004a8f",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#004a8f",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selected: {},
})(MenuItem);
function formatDate(input) {
  let datePart = input.match(/\d+/g),
    year = datePart[0].substring(2),
    month = datePart[1];

  return month + "/" + year;
}
function Reports({ route }) {
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: false,
    standardFontDataUrl: "standard_fonts/",
  };

  const navigate = useNavigate();

  let { id, name, typeId, subtitle } = useParams();
  const { loading, call } = hooks.useRequest();

  const [report, SetReport] = React.useState(null);
  const [bites, SetB] = React.useState(null);
  const [numPages, setNumPages] = React.useState(32);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listReports, setReports] = React.useState(null);

  const [mounted, setMounted] = React.useState(false);

  const mount = React.useCallback(async () => {
    let listA = [];
    let listB = [];
    let listC = null;
    call("AllReports", api.getAllReport(), async (data) => {
      if (data.ok) {
        data?.data?.content.forEach((item, index) => {
          if (item?.reportTypeId?.toString() === typeId) {
            listA.push({
              value: item.id,
              label: formatDate(item.dateReference),
              name: formatDate(item.dateReference),
              trueDate: item.dateReference,
            });
            listB.push({
              id: item.id,
              link: item.link,
              date: item.dateReference,
            });
          }
        });
        listC = await listA.sort((a, b) => {
          if (
            new Date(a?.trueDate).getTime() > new Date(b?.trueDate).getTime()
          ) {
            return -1;
          }
          if (
            new Date(a?.trueDate).getTime() < new Date(b?.trueDate).getTime()
          ) {
            return 1;
          }
          return 0;
        });

        setReports({ options: listC, types: listB });
      }
    });
    call(null, api.getPDF(id), async (response) => {
      if (response.ok) {
        SetB(response.data);
        const bb = base64.decodeArrayBuffer(response.data);
        SetReport(bb);
      } else {
        SetReport(null);
      }
    });
  }, [call, id, typeId]);

  React.useEffect(() => {
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function downloadPDF() {
    const linkSource = `data:application/pdf;base64,${report}`;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function sharePDF() {
    const pdf = new File([bites], `Relatorio_${name}.pdf`, {
      type: "application/pdf",
    });

    if (
      navigator.canShare({
        title: `Relatorio - ${name}.pdf`,
        text: "", // Text to be shared
        url: "", // URL to be shared
        files: [pdf], // An array of File objects to be shared
      })
    ) {
      navigator.share({
        title: `Relatorio - ${name}.pdf`,
        text: "", // Text to be shared
        url: "", // URL to be shared
        files: [pdf], // An array of File objects to be shared
      });
    } else {
      customModal.setInfos("Erro", [
        "Sua versão do navegador não suporta o compartilhamento",
      ]);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changePdf = (event) => {
    navigate(`/report/${name}/${typeId}/${event}/${subtitle}`);
    setMounted(false);
  };
  var viewport_width = document.documentElement.clientWidth;

  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.Header>
          <CustomText
            fontSize={6}
            fontFamily={fonts.bold}
            textColor={1}
            styles={{ marginTop: 0, display: "flex", alignItems: "center" }}>
            <Icons.Clipboard
              style={{
                marginRight: Spacing(3),
                filter:
                  "invert(85%) sepia(23%) saturate(4488%) hue-rotate(246deg) brightness(90%) contrast(89%)",
              }}
            />
            Relatórios
          </CustomText>
          {viewport_width > 600 ? (
            <Styles.SubHeader>
              <Styles.OptionButton
                onClick={() => navigate("/reports")}
                fullWidth={false}
                startIcon={<ChevronLeftIcon />}>
                Voltar
              </Styles.OptionButton>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}>
                <CustomText textColor fontSize={4} fontFamily={fonts.bold}>
                  {name}
                </CustomText>
                {listReports ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                    <Styles.SelectRegion
                      disableUnderline
                      value={id}
                      IconComponent={() => <Styles.SelectIcon />}
                      onChange={(event) => changePdf(event.target.value)}>
                      {listReports?.options?.map((item) => {
                        return (
                          <ListItem key={item.id} value={item.value}>
                            {item.label}
                          </ListItem>
                        );
                      })}
                    </Styles.SelectRegion>
                  </div>
                ) : (
                  <Styles.NoData>⎯</Styles.NoData>
                )}
              </div>
              <div>
                <Styles.OptionButton
                  disabled={!Boolean(report)}
                  endIcon={<MoreVertIcon />}
                  $color
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  onClick={handleClick}>
                  Opções
                </Styles.OptionButton>
                <Styles.Options
                  elevation={0}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  <Styles.OptionItem key={0} onClick={downloadPDF}>
                    <Icons.Download />
                    ⠀Download
                  </Styles.OptionItem>
                  <Styles.OptionItem last key={1} onClick={sharePDF}>
                    <Icons.Share />
                    ⠀Compartilhar
                  </Styles.OptionItem>
                </Styles.Options>
              </div>
            </Styles.SubHeader>
          ) : (
            <>
              <CustomText textColor fontSize={4} fontFamily={fonts.bold}>
                {name}
              </CustomText>

              <Styles.SubHeader>
                <Styles.OptionButton
                  onClick={() => navigate("/reports")}
                  fullWidth={false}
                  startIcon={<ChevronLeftIcon />}>
                  Voltar
                </Styles.OptionButton>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    [MediaQueries.smDown]: {
                      alignItems: "center",
                    },
                  }}>
                  {listReports ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}>
                      <Styles.SelectRegion
                        disableUnderline
                        value={id}
                        IconComponent={() => <Styles.SelectIcon />}
                        onChange={(event) => changePdf(event.target.value)}>
                        {listReports?.options?.map((item) => {
                          return (
                            <ListItem key={item.id} value={item.value}>
                              {item.label}
                            </ListItem>
                          );
                        })}
                      </Styles.SelectRegion>
                    </div>
                  ) : (
                    <Styles.NoData>⎯</Styles.NoData>
                  )}
                </div>
                <div>
                  <Styles.OptionButton
                    disabled={!Boolean(report)}
                    endIcon={<MoreVertIcon />}
                    $color
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    onClick={handleClick}>
                    Opções
                  </Styles.OptionButton>
                  <Styles.Options
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <Styles.OptionItem key={0} onClick={downloadPDF}>
                      <Icons.Download />
                      ⠀Download
                    </Styles.OptionItem>
                    <Styles.OptionItem last key={1} onClick={sharePDF}>
                      <Icons.Share />
                      ⠀Compartilhar
                    </Styles.OptionItem>
                  </Styles.Options>
                </div>
              </Styles.SubHeader>
            </>
          )}
        </Styles.Header>

        {loading ? (
          <CircularProgress />
        ) : report ? (
          <Styles.Pdf>
            <Document
              file={`data:application/pdf;base64,${report}`}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}>
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={viewport_width * 0.85}
              />

              <Styles.PageNav
                style={{
                  position: "absolute",
                  bottom: " 20%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  transition: "opacity ease-in-out 0.2s",
                  boxShadow: "0 30px 40px 0 rgb(16 36 94 / 20%)",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  display: "flex",
                }}>
                <Styles.ButtonNav
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  startIcon={<ChevronLeftIcon />}></Styles.ButtonNav>
                <CustomText
                  fontSize={1.55}
                  styles={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    padding: 0,
                    flex: 1,
                    margin: 0,
                  }}>
                  {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
                </CustomText>

                <Styles.ButtonNav
                  startIcon={<ChevronRightIcon />}
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}></Styles.ButtonNav>
              </Styles.PageNav>
            </Document>
          </Styles.Pdf>
        ) : (
          <CustomText fontSize={2}>PDF não encontrado!</CustomText>
        )}
      </Styles.Content>
      <Footer />
    </Styles.Container>
  );
}

export default Reports;
