import { ReactComponent as Megaphone } from "../assets/svg/comunicados-icon.svg";
import { ReactComponent as TalkToUs } from "../assets/svg/fale-conosco-icon.svg";
import { ReactComponent as Home } from "../assets/svg/home-icon.svg";
import { ReactComponent as Settings } from "../assets/svg/icone-configurações.svg";
import { ReactComponent as Close } from "../assets/svg/icone-fechar.svg";
import { ReactComponent as Leave } from "../assets/svg/icone-sair.svg";
import { ReactComponent as Reports } from "../assets/svg/relatorios-icon.svg";
import { ReactComponent as CCEE } from "../assets/svg/CCEE-icon.svg";
import { ReactComponent as Consumo } from "../assets/svg/consumo-icon.svg";
import { ReactComponent as Financeiro } from "../assets/svg/financeiro-icon.svg";
import { ReactComponent as Facebook } from "../assets/svg/icone-facebook.svg";
import { ReactComponent as Insta } from "../assets/svg/icone-instagram.svg";
import { ReactComponent as Linkedin } from "../assets/svg/icone-linkedin.svg";
import { ReactComponent as Youtube } from "../assets/svg/icone-youtube.svg";
import { ReactComponent as Edit } from "../assets/svg/icone-editar.svg";
import { ReactComponent as Clipboard } from "../assets/svg/icone-secao-relatorio.svg";
import { ReactComponent as Share } from "../assets/svg/icone-compartilhar.svg";
import { ReactComponent as Download } from "../assets/svg/icone-download.svg";
import { ReactComponent as Indicadores } from "../assets/svg/icone-indicadores.svg";
import { ReactComponent as Chatbot } from "../assets/svg/icone-chatbot.svg";
import { ReactComponent as Menu } from "../assets/svg/icone-menu.svg";

const Icons = {
  Megaphone,
  TalkToUs,
  Home,
  Settings,
  Close,
  Leave,
  Reports,
  CCEE,
  Financeiro,
  Consumo,
  Facebook,
  Insta,
  Linkedin,
  Youtube,
  Edit,
  Clipboard,
  Download,
  Share,
  Indicadores,
  Chatbot,
  Menu,
};

export default Icons;
