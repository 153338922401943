import styled from "styled-components";
import colors from "../../config/colors";

import { MediaQueries, Spacing } from "../../config";
import { ButtonText, FontStyles } from "../../genericComponents";
import { MenuItem, Select, Menu } from "@material-ui/core";
import { ReactComponent as Pin } from "../../assets/svg/icone-flecha-select.svg";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100vw",
    height: `calc(100vh - ${spacing(17)}px)`,
    flexDirection: "column",

    justifyContent: "flex-start",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "none",
  };
});

const Content = styled.div(({ theme, alternative, marginTop }) => {
  const { palette: colors, spacing } = theme;
  return {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: `100%`,
    backgroundColor: alternative ? colors.background.secondary : "transparent",
    paddingInline: "13%",
    paddingBlock: spacing(8, 15.5),
    paddingTop: marginTop && spacing(6),
    [MediaQueries.mdDown]: {
      paddingInline: spacing(4),
    },
  };
});

const Header = styled.div(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: "100%",
    height: "fit-content",
    marginBottom: Spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
});
const SubHeader = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",

    display: "flex",
    justifyContent: "space-evenly",
    marginTop: spacing(4),
  };
});
const Title = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: colors.purple,
    opacity: 0.88,
    margin: 0,
    textAlign: "left",
    ...FontStyles.bold42,
    paddingTop: Spacing(8),
  };
});

const Subtitle = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: colors.grayText,
    ...FontStyles.medium16,
    opacity: 0.88,
    margin: 0,
    paddingTop: Spacing(2.5),
    paddingBottom: Spacing(3),
  };
});

//----

const Card = styled.div(({ $Image }) => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    border: "1px solid #004b90",
    borderRadius: Spacing(0.6),
  };
});
const CardHeader = styled.div(({ theme, noLine }) => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: !noLine && Spacing(4),
    borderBottom: !noLine && "1px solid #004b90",
  };
});
const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    margin: 0,
    padding: 0,
    color: colors.text.secondary,
    ...FontStyles.bold22,
  };
});

const SelectLabel = styled.p(({ theme }) => {
  return {
    margin: 0,
    padding: 0,
    marginRight: Spacing(1),
    color: "#C2C2C2",
    ...FontStyles.bold22,
  };
});
const SelectRegion = styled(Select)(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    padding: 0,
    color: "#C2C2C2",
    marginLeft: spacing(2),
    ...FontStyles.bold22,
    [MediaQueries.smDown]: {
      ...FontStyles.bold18,
    },
    "& .MuiSelect-select": {
      padding: 0,
      backgroundColor: "white",
    },
    "& .MuiSelect-selectMenu.Mui-selected": {
      padding: 0,
      backgroundColor: "white",
    },
    "& Mui-selected": {
      color: "red",
    },
    "& .Mui-selected": {
      color: "red",
    },
  };
});
const NoData = styled.p(() => {
  return {
    margin: 0,
    padding: 0,
    color: "#C2C2C2",
    ...FontStyles.bold22,
  };
});
const SelectItens = styled(MenuItem)(() => {
  return {
    color: "#C2C2C2",
    ...FontStyles.bold16,
    ":hover": {
      backgroundColor: "#5C42DB60",
      color: "white",
    },
  };
});
const SelectIcon = styled(Pin)(() => {
  return {
    color: "#C2C2C2",
    width: Spacing(2),
    height: Spacing(2),
    fill: "#C2C2C2",
  };
});

const ShadowCard = styled.div(() => {
  return {
    marginTop: Spacing(6),

    width: "100%",
    height: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: `0px ${Spacing(10.5)}px ${Spacing(7.5)}px #2C4D741A`,
    borderRadius: Spacing(0.6),
    padding: Spacing(5.5),
  };
});

const ReportsCards = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.background.paper,

    margin: "auto",
    padding: spacing(6),
    borderRadius: spacing(0.5),
    boxShadow: "5px 5px 15px #0000001A",

    height: "100%",
    maxWidth: spacing(53),
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    marginInline: spacing(2),
    minWidth: spacing(34.37),
    [MediaQueries.mdDown]: {
      minWidth: spacing(42),
    },
  };
});

const ReportsIcon = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.background.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    height: spacing(20.5),
    width: spacing(20.5),
    borderRadius: spacing(20),
  };
});

const PageNav = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    position: "absolute",
    bottom: " 20%",
    left: "50%",

    transition: "opacity ease-in-out 0.2s",
    boxShadow: "0 30px 40px 0 rgb(16 36 94 / 40%)",
    borderRadius: "4px",
    backgroundColor: "white",
    display: colors.background.paper,
    padding: spacing(1),
    opacity: 0.6,
    ":hover": {
      opacity: 1,
    },
  };
});

const ButtonNav = styled(ButtonText)(({ theme, $color }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.medium12,
    padding: spacing(0.85),
    minWidth: 0,
    color: $color && colors.background.alternative,
    "& .MuiButton-startIcon": {
      margin: 0,
    },
  };
});
const Pdf = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    borderRadius: spacing(0.2),
    boxShadow: "0px 24px 80px #2C4D7426",
    width: "auto'",
  };
});

const Options = styled(Menu)(() => {
  return {
    boxShadow: "5px 5px 15px #00000029",
    "& .MuiMenu-paper": {
      boxShadow: "5px 5px 15px #00000029",
    },
  };
});

const OptionItem = styled(MenuItem)(({ theme, last }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: "#C2C2C2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing(1),
    borderBottom: !last && "2px solid #C2C2C2",

    ...FontStyles.bold16,
    ":hover": {
      backgroundColor: colors.background.alternative + "60",
      color: colors.background.alternative,
    },
  };
});

const OptionButton = styled(ButtonText)(({ theme, $color }) => {
  return {
    color: $color && "#C2C2C2",
    height: " min-content",
  };
});

const Styles = {
  Container,
  Content,
  Title,
  Subtitle,
  Header,

  Card,
  CardTitle,
  CardHeader,
  SelectRegion,
  SelectItens,
  SelectLabel,
  SelectIcon,

  ShadowCard,

  ReportsCards,

  ReportsIcon,
  NoData,
  PageNav,
  ButtonNav,
  Pdf,
  SubHeader,
  Options,
  OptionItem,

  OptionButton,
};

export default Styles;
