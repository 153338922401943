import styled from "styled-components";
import React from "react";
import Grid from '@material-ui/core/Grid';

import fonts from "../../../config/fonts";
import { IconButton } from "@material-ui/core";
import CustomText from "../../customText/CustomText";
import Styles from "./FooterStyles";
import images from "../../../config/images";
import { Icons, MediaQueries } from "../../../config";


const FooterPage = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    paddingBlock: spacing(6),
    display: "flex",
    justifyContent: "space-between",
    [MediaQueries.smDown]: {
      alignItems: "center",
      flexDirection: "column",
    },
  };
});
const ButtonIcon = styled(IconButton)(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    borderRadius: 0,
    marginRight: spacing(2),
  };
});

function Footer() {
  return (
    <FooterPage>
      <Grid container justifyContent="center">
      <img src={images.onda} className="footeronda"  />
          <Grid item xs={12} sm={12} xl={4} md={4} justifyContent="center">
            
          </Grid>
          <Grid item xs={12} sm={12} xl={4} md={4}>
            <Styles.FooterConteudo>
              <Styles.EcomLogoFooter src={images.logoaegea} />
            </Styles.FooterConteudo>
                 
          </Grid>
          <Grid item xs={12} sm={12} xl={4} md={4}>
            
            
          </Grid>
        </Grid>
    {/* <CustomText
      fontSize={3}
      fontFamily={fonts.regular}
      styles={{ color: "#C2C2C2" }}></CustomText><div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} xl={4} md={4}>
            <Styles.EcomLogo src={images.LogoEcomFooter} />
            <ButtonIcon>
              <Icons.Linkedin
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/ecom-energia/posts/?feedView=all&viewAsMember=true"
                  );
                } } />
            </ButtonIcon>
            <ButtonIcon>
              <Icons.Facebook
                onClick={() => {
                  window.open("https://www.facebook.com/ecomenergia/");
                } } />
            </ButtonIcon>
            <ButtonIcon>
              <Icons.Insta
                onClick={() => {
                  window.open("https://www.instagram.com/ecomenergia/");
                } } />
            </ButtonIcon>
            <ButtonIcon>
              <Icons.Youtube
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/channel/UC9cs7TzwX8vq4G4NcvoOiPA"
                  );
                } } />
            </ButtonIcon>
          </Grid>
          <Grid item xs={12} sm={12} xl={4} md={4}>

          </Grid>
          <Grid item xs={12} sm={12} xl={4} md={4}>
            
          </Grid>
        </Grid>
        </>
      </div> */}
    </FooterPage>
  );
}

export default Footer;
